// AboutSection.jsx

import React from 'react';
import { Container, Grid, Box, Typography, List, ListItem, IconButton, Button } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import InsightsIcon from '@mui/icons-material/Insights';
import FutureIcon from '@mui/icons-material/Update';
import { useNavigate } from 'react-router-dom';
import haiImage from '../images/hai.png'; // Correct image import

const AboutSection = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/'); // Navigate back to the home page
  };

  const handleScheduleConsultation = () => {
    // navigate to external consultation scheduling link
    window.location.href = 'https://hivetechnology.setmore.com/services/f3a9069c-f508-4431-8863-0452bc6d62ef?source=easyshare';
  };

  return (
    <Box
      id="about"
      sx={{
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '100%',
        backgroundColor: '#1a1a1a', // Matte black background
        color: '#E0FFFD', // Neon cyan text for contrast
        overflowY: 'scroll',
        top: 0,
        pointerEvents: 'all',
        paddingTop: '64px',
        backdropFilter: 'blur(10px)', // Adding some blur for depth
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'fixed',
          top: '16px',
          right: '16px',
          zIndex: 3,
          backgroundColor: '#333', // Darker background for the button
          color: '#E0FFFD', // Cyan close button
          '&:hover': {
            backgroundColor: '#555', // Hover effect
          },
        }}
      >
        <CloseIcon />
      </IconButton>

      <Container sx={{ py: 5 }}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <BusinessIcon sx={{ fontSize: 40, color: '#FF007F' }} /> {/* Icon for AI business */}
              <Typography variant="h3" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
                About Hive AI
              </Typography>
              <Typography variant="body1" paragraph>
                Our strategy revolves around harnessing cutting-edge machine learning techniques to uncover insights
                that push the boundaries of human intuition. By doing so, we can delve into uncharted territories of AI
                research, expand the repertoire of available resources, and unlock unprecedented performance capabilities.
              </Typography>
              <List sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://twitter.com/hiveforensics" target="_blank" aria-label="Twitter" sx={{ color: '#1DA1F2' }}>
                    <TwitterIcon fontSize="large" />
                  </IconButton>
                </ListItem>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://facebook.com/hiveforensics" target="_blank" aria-label="Facebook" sx={{ color: '#4267B2' }}>
                    <FacebookIcon fontSize="large" />
                  </IconButton>
                </ListItem>
                <ListItem disableGutters>
                  <IconButton component="a" href="https://linkedin.com/company/hive-ai" target="_blank" aria-label="LinkedIn" sx={{ color: '#0A66C2' }}>
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </ListItem>
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <InsightsIcon sx={{ fontSize: 40, color: '#FF007F' }} /> {/* Icon for AI insights */}
              <Typography variant="h4" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
                Our Mission
              </Typography>
              <Typography variant="body1" paragraph>
                Our relentless pursuit of excellence leads us to develop breakthrough AI systems that learn, adapt,
                and solve complex problems. By pushing the boundaries of knowledge, we open doors to limitless possibilities.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: '#2C2C2C', // Matte gray for contrast
                p: 5,
                mt: 5,
                borderRadius: 2,
                boxShadow: '0px 0px 15px 2px rgba(255, 0, 127, 0.6)', // Neon pink glow effect
              }}
            >
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={3}>
                  <Box component="img" src={haiImage} alt="AI Solutions" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h4" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
                    AI Solutions for Every Business
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Our AI models are designed to help businesses automate, optimize, and innovate. Whether through LLMs or image-based AI models, we provide powerful solutions for various industries.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: '#FF007F', // Neon pink button
                      '&:hover': {
                        backgroundColor: '#FF3399',
                      },
                    }}
                    onClick={handleScheduleConsultation}
                  >
                    Schedule a Consultation
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <InsightsIcon sx={{ fontSize: 40, color: '#FF007F' }} /> {/* Icon for data-driven innovation */}
              <Typography variant="h4" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
                Data-Driven Innovation
              </Typography>
              <Typography variant="body1" paragraph>
                We are at the forefront of revolutionizing the AI landscape by harnessing the power of data and artificial intelligence. Through our
                innovative approach, we drive transformative advancements in various domains, shaping the future of AI.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={6}>
            <Box mb={9} sx={{ textAlign: 'center' }}>
              <FutureIcon sx={{ fontSize: 40, color: '#FF007F' }} /> {/* Icon for AI future innovation */}
              <Typography variant="h4" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
                Embracing the Future with AI-driven Innovation
              </Typography>
              <Typography variant="body1" paragraph>
                With our unwavering commitment to research and development, we embrace the future by continuously innovating and delivering AI-driven
                solutions that redefine industries, improve lives, and shape a better tomorrow.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutSection;

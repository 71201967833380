//WorkSection.jsx




import React from 'react';
import { Container, Grid, Box, Typography, Link, IconButton, Paper, Divider } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../images/logo.png'; // Adjust the path to your logo image

const WorkSection = () => (
  <Box
    id="work"
    sx={{
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      backgroundColor: '#1a1a1a', // Matte black background
      overflowY: 'scroll',
      overflowX: 'hidden',
      top: 0,
      pointerEvents: 'all',
      paddingTop: '5rem',
      color: '#E0FFFD', // Neon cyan text color
    }}
  >
    <Container>
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Box mb={5} textAlign="center">
            <Typography variant="h4" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
              Our Research and Innovation
            </Typography>
            <Typography variant="body1" color="#E0FFFD">
              Discover our pioneering AI research and innovative solutions designed to tackle complex industry challenges and drive operational excellence.
            </Typography>
          </Box>
        </Grid>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/sherlock.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: '0 0 20px rgba(255, 0, 127, 0.5)', // Neon pink glow effect
                transition: 'box-shadow .3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 0 30px rgba(255, 0, 127, 0.8)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Fraud Detection<br />
                  <span style={{ fontSize: '0.85rem' }}>Case Study</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/sherlock.png" alt="Fraud Detection Case Study" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/icp.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: '0 0 20px rgba(255, 0, 127, 0.5)',
                transition: 'box-shadow .3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 0 30px rgba(255, 0, 127, 0.8)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Internet Computer Protocol<br />
                  <span style={{ fontSize: '0.85rem' }}>Research Paper</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/icp.webp" alt="Internet Computer Protocol Research Paper" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              component="a"
              href="/hive.pdf"
              sx={{
                display: 'block',
                position: 'relative',
                textDecoration: 'none',
                boxShadow: '0 0 20px rgba(255, 0, 127, 0.5)',
                transition: 'box-shadow .3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 0 30px rgba(255, 0, 127, 0.8)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
                  opacity: 0,
                  transition: 'opacity .3s ease-in-out',
                  display: 'flex',
                  alignItems: 'flex-end',
                  p: 2,
                  color: '#fff',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="body2">
                  Smart Business Solutions<br />
                  <span style={{ fontSize: '0.85rem' }}>Innovation Spotlight</span>
                </Typography>
              </Box>
              <Box component="img" src="/images/hai.png" alt="Smart Business Solutions" sx={{ width: '100%', height: 'auto', borderRadius: 1 }} />
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Box mt={5} mb={5} textAlign="center">
            <Typography variant="h5" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
              Meet Our Experts
            </Typography>
            <Typography variant="body1" color="#E0FFFD">
              Our team of AI specialists and researchers is dedicated to pushing the boundaries of technology.{' '}
              <Link href="#!team" underline="hover" sx={{ color: '#FF007F' }}>Learn more about our team</Link>.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Box mb={9} textAlign="center">
            <Typography variant="h5" gutterBottom sx={{ color: '#FF007F', fontWeight: 'bold' }}>
              Interested in Collaboration?
            </Typography>
            <Typography variant="body1" color="#E0FFFD">
              We'd love to hear from you! Reach out to us at{' '}
              <Link href="mailto:info@hiveai.tech" underline="hover" sx={{ color: '#FF007F' }}>info@hiveai.tech</Link>.
            </Typography>
          </Box>
        </Grid>

        <Divider sx={{ mt: 5, mb: 5, backgroundColor: '#FF007F' }} /> {/* Neon divider */}

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Box component="img" src={logo} alt="Hive AI Logo" width={85} />
            <Typography variant="caption" display="block" sx={{ mt: 2 }} color="#E0FFFD">
              Hive AI Research
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <IconButton component="a" href="https://twitter.com/hiveforensics" target="_blank" sx={{ color: '#1DA1F2' }}>
              <TwitterIcon fontSize="large" />
            </IconButton>
            <IconButton component="a" href="https://www.linkedin.com/company/hive-ai-tech" target="_blank" sx={{ color: '#0A66C2' }}>
              <LinkedInIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Link href="/" underline="hover" sx={{ color: '#FF007F' }}>
              Close
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default WorkSection;
